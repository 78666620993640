import { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import { debounce } from 'lodash';

export const useFetchTableData = (fetchFunction: Function, defaultLimit: number = 10) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(defaultLimit);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const [filters, setFilters] = useState<any>({});

    const fetchData = useCallback((page: number, limit: number, query: string, filters: any) => {
        setLoading(true);

        const params = { page, limit, query, ...filters };

        fetchFunction(params)
            .then((res: any) => {
                setData(res?.data || []);
                setTotal(res?.totalItems || 0);
            })
            .finally(() => setLoading(false));
    }, [fetchFunction]);

    useEffect(() => {
        loadData();
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetch = useCallback(debounce(fetchData, 300), [fetchData]);

    const handleSearchChange = (value: string) => {
        setQuery(value);
        setPage(1);
        debouncedFetch(1, limit, value, { ...filters });
    };

    const handleTableChange = (pagination: any) => {
        setPage(pagination.current);
        setLimit(pagination.pageSize);
        fetchData(pagination.current, pagination.pageSize, query, filters);
    };

    const loadData = useCallback(() => {
        fetchData(page, limit, query, filters);
    }, [fetchData, limit, page, query, filters]);

    const changeFiltersAndRefresh = useCallback((data: any) => {
        const newFilters = { ...filters, ...data };
        setFilters(newFilters);
        fetchData(page, limit, query, newFilters);
    }, [fetchData, filters, limit, page, query]);

    return {
        data,
        total,
        loading,
        page,
        limit,
        query,
        setQuery,
        handleSearchChange,
        handleTableChange,
        loadData,
        filters,
        setFilters,
        changeFiltersAndRefresh,
    };
};
