import React, {useState} from 'react';
import {Button, Form, FormProps, Input} from "antd";
import {useNavigate} from "react-router-dom";
import {VideoFieldType} from "../VideoEdit/props";
import {VideoService} from "../../services/video.service";

export const VideoAdd = () => {
    const navigate = useNavigate();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const onFinish: FormProps<VideoFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            youtubeCode: values.youtubeCode,
        };

        setLoadingSubmit(true);
        VideoService.create(toSave)
            .then(() => {
                alert('Запись успешно добавлен!');
                navigate('/videos');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при добавлении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    return (
        <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<VideoFieldType>
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<VideoFieldType>
                label="YouTube код"
                name="youtubeCode"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Добавить'}
                </Button>
            </Form.Item>
        </Form>
    );
};