import React, {useMemo} from 'react';
import {useFetchTableData} from "../../hooks/useFetchTableData";
import {SearchInput} from "../../components/SearchInput";
import {DataTable} from "../../components/DataTable";
import {UsersService} from "../../services/users.service";
import {showObviousDate} from "../../shared/utils";
import {Button, Space} from "antd";

export const Users = () => {
    const {
        data,
        total,
        loading,
        page,
        limit,
        query,
        handleSearchChange,
        handleTableChange,
    } = useFetchTableData(UsersService.getAll.bind(UsersService));

    const columns = useMemo(() =>[
        {
            title: 'ФИО',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Реф. код',
            dataIndex: 'referralCode',
            key: 'referralCode',
        },
        {
            title: 'Город',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Дата регистрации',
            dataIndex: 'registerDate',
            key: 'registerDate',
            render: showObviousDate,
        },
    ], []);

    return (
        <div>
            <Space direction="horizontal" style={{ marginBottom: 20 }}>
                <SearchInput value={query} onChange={handleSearchChange} />
            </Space>
            <DataTable
                data={data}
                columns={columns}
                loading={loading}
                total={total}
                page={page}
                limit={limit}
                onTableChange={handleTableChange}
            />
        </div>
    );
};