import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import {LeftColumn} from "./components/LeftColumn";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Orders} from "./pages/Orders";
import {Brands} from "./pages/Brands";
import {Faq} from "./pages/Faq";
import {Products} from "./pages/Products";
import {Users} from "./pages/Users";
import {Videos} from "./pages/Videos";
import {ProductEdit} from "./pages/ProductEdit";
import {ProductAdd} from "./pages/ProductAdd";
import {OrderDetail} from "./pages/OrderDetail";
import {BrandEdit} from "./pages/BrandEdit";
import {BrandAdd} from "./pages/BrandAdd";
import {FaqEdit} from "./pages/FaqEdit";
import {FaqAdd} from "./pages/FaqAdd";
import {VideoAdd} from "./pages/VideoAdd";
import {VideoEdit} from "./pages/VideoEdit";
import {Login} from "./pages/Login";
import {Warehouses} from "./pages/Warehouses";
import {WarehouseAdd} from "./pages/WarehouseAdd";
import {WarehouseEdit} from "./pages/WarehouseEdit";


dayjs.locale('ru');

export const App = () => {
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        setIsLogged(!!localStorage.getItem('access_token'));
    }, []);

    return (
        <ConfigProvider locale={ruRU}>
            <Router>
                <StyledContainer>
                    <StyledHeader>
                        <div className="logo">OtanApp Admin</div>
                    </StyledHeader>
                    <StyledMain>
                        {isLogged && (
                            <StyledLeftColumn>
                                <LeftColumn />
                            </StyledLeftColumn>
                        )}
                        <StyledContentColumn>
                            <Routes>
                                <Route path="/" element={<Navigate to="/login" />} />
                                <Route path="/login" element={<Login />} />
                                {/* ORDERS */}
                                <Route path="/orders" element={<Orders />} />
                                <Route path="/orders/detail/:id" element={<OrderDetail />} />
                                {/* PRODUCTS */}
                                <Route path="/products" element={<Products />} />
                                <Route path="/products/edit/:id" element={<ProductEdit />} />
                                <Route path="/products/add" element={<ProductAdd />} />
                                {/* BRANDS */}
                                <Route path="/brands" element={<Brands />} />
                                <Route path="/brands/edit/:id" element={<BrandEdit />} />
                                <Route path="/brands/add" element={<BrandAdd />} />
                                {/* WAREHOUSES */}
                                <Route path="/warehouses" element={<Warehouses />} />
                                <Route path="/warehouses/add" element={<WarehouseAdd />} />
                                <Route path="/warehouses/edit/:id" element={<WarehouseEdit />} />
                                {/* FAQ */}
                                <Route path="/faq" element={<Faq />} />
                                <Route path="/faq/edit/:id" element={<FaqEdit />} />
                                <Route path="/faq/add" element={<FaqAdd />} />
                                {/* VIDEOS */}
                                <Route path="/videos" element={<Videos />} />
                                <Route path="/videos/edit/:id" element={<VideoEdit />} />
                                <Route path="/videos/add" element={<VideoAdd />} />
                                {/* USERS */}
                                <Route path="/users" element={<Users />} />
                            </Routes>
                        </StyledContentColumn>
                    </StyledMain>
                </StyledContainer>
            </Router>
        </ConfigProvider>
    );
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const StyledHeader = styled.div`
    background: #0CBC71;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border-bottom: 1px solid #ababab;
    display: flex;
    align-items: center;
    
    .logo {
        font-size: 18px;
        font-weight: bold;
        margin-left: 20px;
        color: #fff;
    }
`;

const StyledMain = styled.div`
    display: flex;
    flex: 1;
`;

const StyledLeftColumn = styled.div`
    background: #fff;
    width: 200px;
    height: 100%;
    border-right: 1px solid #ababab;
`;

const StyledContentColumn = styled.div`
    background: #fff;
    flex: 1;
    height: 100%;
    padding: 20px;
`;