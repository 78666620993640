import React from 'react';
import { Table } from 'antd';

interface DataTableProps {
    data: any[];
    columns: any[];
    loading: boolean;
    total: number;
    page: number;
    limit: number;
    onTableChange: (pagination: any) => void;
}

export const DataTable: React.FC<DataTableProps> = ({
    data,
    columns,
    loading,
    total,
    page,
    limit,
    onTableChange,
}) => {
    return (
        <Table
            dataSource={data}
            columns={columns}
            rowKey="_id"
            pagination={{
                current: page,
                pageSize: limit,
                total: total,
                showSizeChanger: true,
            }}
            loading={loading}
            onChange={onTableChange}
        />
    );
};
