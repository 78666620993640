import React, {useState} from 'react';
import {ProductsService} from "../../services/products.service";
import {Button, Form, FormProps, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import {ProductFieldType} from "../ProductEdit/props";
import {useNavigate} from "react-router-dom";

export const ProductAdd = () => {
    const navigate = useNavigate();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const onFinish: FormProps<ProductFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            description: values.description,
            priceGroup: {
                default: Number(values.priceDefault),
                expert: Number(values.priceExpert),
                leader: Number(values.priceLeader),
                top: Number(values.priceTop),
            },
            countInBlock: Number(values.countInBlock),
            weight: Number(values.weight),
            imageUrl: values.imageUrl,
            brandId: values.brandId,
        };

        setLoadingSubmit(true);
        ProductsService.create(toSave)
            .then(res => {
                console.log(res);
                alert('Продукт успешно добавлен!');
                navigate('/products');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при добавлении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    return (
        <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<ProductFieldType>
                label="Название товара"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Описание товара"
                name="description"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <TextArea />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (партнер)"
                name="priceDefault"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (эксперт)"
                name="priceExpert"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (лидер)"
                name="priceLeader"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (топ-партнер)"
                name="priceTop"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Кол-во упаковок в блоке"
                name="countInBlock"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Вес (кг)"
                name="weight"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Ссылка на изображение"
                name="imageUrl"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="ID бренда"
                name="brandId"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Добавить'}
                </Button>
            </Form.Item>
        </Form>
    );
};