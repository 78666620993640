import axios, { AxiosInstance } from 'axios';
import {API_URL} from "../config";

interface BaseServiceOptions {
    baseURL?: string;
    resourcePath: string;
}

class BaseService<T> {
    protected api: AxiosInstance;
    protected resourcePath: string;

    constructor(options: BaseServiceOptions) {
        this.api = axios.create({
            baseURL: options?.baseURL || API_URL,
        });
        this.resourcePath = options.resourcePath;

        this.api.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('access_token'); // Или sessionStorage, в зависимости от стратегии
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    // Получить все записи
    async getAll({ page = 1, limit = 10, query = '' }): Promise<any> {
        try {
            const response = await this.api.get<T[]>(this.resourcePath, { params: { page, limit, query } });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Получить запись по ID
    async getById(id: string) {
        try {
            const response = await this.api.get<T>(`${this.resourcePath}/${id}`);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Создать новую запись
    async create(data: Partial<T>) {
        try {
            const response = await this.api.post<T>(this.resourcePath, data);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Обновить запись по ID
    async update(id: string, data: Partial<T>) {
        try {
            const response = await this.api.patch<T>(`${this.resourcePath}/${id}`, data);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Удалить запись по ID
    async delete(id: string) {
        try {
            const response = await this.api.delete(`${this.resourcePath}/${id}`);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    // Обработчик ошибок
    protected handleError(error: any): void {
        if (error.response?.status === 401) {
            console.log('Unauthorized, redirecting to login...');
            localStorage.removeItem('access_token');
            window.location.href = '/login'; // Перенаправляем на страницу логина
        } else {
            console.error('API error:', error);
        }
        throw error;
    }
}

export default BaseService;
