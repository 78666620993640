import {
    OrderPaymentStatus,
    OrderPaymentStatusLabels,
    OrderStatus,
    OrderStatusLabels
} from "../../shared/types/order.type";
import React from "react";

export function getStatusLabelWithColor (value: keyof typeof OrderStatusLabels) {
    let color = '';
    switch (value) {
        case OrderStatus.PENDING:
            color = 'orange';
            break;
        case OrderStatus.ACCEPTED:
            color = 'orange';
            break;
        case OrderStatus.DELIVERED:
            color = 'green';
            break;
        case OrderStatus.CANCELLED:
            color = 'red';
            break;
        default:
            color = 'inherit'; // по умолчанию стандартный цвет
    }
    return (
        <span style={{ fontWeight: 'bold', color }}>
            {OrderStatusLabels[value]}
        </span>
    );
}

export function getStatusPaymentLabelWithColor (value: keyof typeof OrderPaymentStatusLabels) {
    let color = '';
    switch (value) {
        case OrderPaymentStatus.PENDING:
            color = 'orange';
            break;
        case OrderPaymentStatus.PAID:
            color = 'green';
            break;
        case OrderPaymentStatus.CANCELLED:
            color = 'red';
            break;
        case OrderPaymentStatus.ERROR:
            color = 'red';
            break;
        default:
            color = 'inherit'; // по умолчанию стандартный цвет
    }
    return (
        <span style={{ fontWeight: 'bold', color }}>
            {OrderPaymentStatusLabels[value]}
        </span>
    );
}
