import React, {useCallback, useMemo} from 'react';
import {OrdersService} from "../../services/orders.service";
import {showObviousDate} from "../../shared/utils";
import {useFetchTableData} from "../../hooks/useFetchTableData";
import {DataTable} from "../../components/DataTable";
import {SearchInput} from "../../components/SearchInput";
import {useNavigate} from "react-router-dom";
import {getStatusLabelWithColor, getStatusPaymentLabelWithColor} from "./utils";

import { DatePicker, Space, Button, Flex } from 'antd';
import dayjs from 'dayjs';
import CustomerSelect from "../../components/CustomerSelect/component";
import BrandSelect from "../../components/BrandSelect/component";
import StatusSelect from "../../components/StatusSelect/component";
import {OrderPaymentStatus, OrderStatus} from "../../shared/types/order.type";
import StatusPaymentSelect from "../../components/StatusPaymentSelect/component";

const { RangePicker } = DatePicker;

export const Orders = () => {
    const navigate = useNavigate();

    const {
        data,
        total,
        loading,
        page,
        limit,
        query,
        filters,
        handleSearchChange,
        handleTableChange,
        changeFiltersAndRefresh,
    } = useFetchTableData(OrdersService.filter.bind(OrdersService));

    const openOrder = useCallback((id: string) => {
        return () => {
            if (!id) return;
            navigate(`/orders/detail/${id}`);
        };
    }, [navigate]);

    const columns = useMemo(() =>[
        {
            title: '№',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Бренд',
            dataIndex: ['brand', 'name'],
            key: 'brand.name',
        },
        {
            title: 'Статус заказа',
            dataIndex: 'status',
            key: 'status',
            render: getStatusLabelWithColor,
        },
        {
            title: 'Статус оплаты',
            dataIndex: 'statusPayment',
            key: 'statusPayment',
            render: getStatusPaymentLabelWithColor,
        },
        {
            title: 'Заказчик',
            dataIndex: 'customerFullName',
            key: 'customerFullName',
        },
        {
            title: 'Блоки',
            dataIndex: 'totalCount',
            key: 'totalCount',
        },
        {
            title: 'Сумма',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Дата заказа',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: showObviousDate,
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, item: any) => {
                return (
                    <Flex wrap gap="2px" style={{ width: 100 }}>
                        <Button type="primary" size="small" block onClick={openOrder(item._id)}>
                            Посмотреть
                        </Button>
                    </Flex>
                );
            },
        },
    ], [openOrder]);

    const handleDateRangeChange = useCallback((value: any) => {
        const [startDate, endDate] = value || ['', ''];

        changeFiltersAndRefresh({
            ...filters,
            startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
            endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
        });
    }, [filters, changeFiltersAndRefresh]);

    const onChangeCustomerSelect = (customerId: string) => {
        if (!customerId) return;
        changeFiltersAndRefresh({
            ...filters,
            customerId,
        });
    };

    const onChangeBrandSelect = (brandId: string) => {
        if (!brandId) return;
        changeFiltersAndRefresh({
            ...filters,
            brandId,
        });
    };

    const onChangeStatusSelect = (status: OrderStatus) => {
      if (!status) return;
      changeFiltersAndRefresh({
          ...filters,
          status,
      });
    };

    const onChangeStatusPaymentSelect = (status: OrderPaymentStatus) => {
      if (!status) return;
      changeFiltersAndRefresh({
          ...filters,
          statusPayment: status,
      });
    };

    const resetFilters = () => {
        window.location.reload();
    };

    const exportExcel = () => {
        OrdersService.exportExcel({ page, limit, query, ...filters })
            .then((res) => {
                if (!res) return;
                downloadExcelFile(res);
            });
    };

    const downloadExcelFile = (res: any) => {
        // Создаем URL для объекта blob
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));

        // Создаем временную ссылку для скачивания
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.xlsx'); // Указываем имя файла
        document.body.appendChild(link);
        link.click();

        // Удаляем ссылку после скачивания
        link.parentNode?.removeChild(link);
    };

    return (
        <div>
            <Space direction="horizontal" style={{ marginBottom: 20 }}>
                <SearchInput value={query} onChange={handleSearchChange} />
                <RangePicker onChange={handleDateRangeChange} />
                <CustomerSelect onChange={onChangeCustomerSelect} />
                <BrandSelect onChange={onChangeBrandSelect} />
                <StatusSelect onChange={onChangeStatusSelect} />
                <StatusPaymentSelect onChange={onChangeStatusPaymentSelect} />
                <Button onClick={resetFilters}>Сбросить</Button>
                <Button onClick={exportExcel}>Скачать Excel</Button>
            </Space>
            <DataTable
                data={data}
                columns={columns}
                loading={loading}
                total={total}
                page={page}
                limit={limit}
                onTableChange={handleTableChange}
            />
        </div>
    );
};