import React, {useState} from 'react';
import {Button, Col, Form, FormProps, Input, Row} from "antd";
import {useNavigate} from "react-router-dom";
import {BrandFieldType} from "../BrandEdit/props";
import {BrandsService} from "../../services/brands.service";

export const BrandAdd = () => {
    const navigate = useNavigate();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const onFinish: FormProps<BrandFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            logo: values.logo,
            defaultStart: Number(values.defaultStart),
            defaultEnd: Number(values.defaultEnd),
            expertStart: Number(values.expertStart),
            expertEnd: Number(values.expertEnd),
            leaderStart: Number(values.leaderStart),
            leaderEnd: Number(values.leaderEnd),
            topStart: Number(values.topStart),
            topEnd: Number(values.topEnd),
        };

        setLoadingSubmit(true);
        BrandsService.create(toSave)
            .then(res => {
                console.log(res);
                alert('Запись успешно добавлен!');
                navigate('/brands');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при добавлении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<BrandFieldType>
                label="Название бренда"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<BrandFieldType>
                label="Ссылка на логотип"
                name="logo"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>


            <Form.Item label="Партнер (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="defaultStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="defaultEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>


            <Form.Item label="Эксперт (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="expertStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="expertEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>


            <Form.Item label="Лидер (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="leaderStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="leaderEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>


            <Form.Item label="Топ-партнер (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="topStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="topEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Добавить'}
                </Button>
            </Form.Item>
        </Form>
    );
};