import React, {useEffect, useMemo, useState} from 'react';
import {Select} from "antd";
import {BrandsService} from "../../services/brands.service";

interface Props {
    onChange: (val: any) => void;
}

const BrandSelect = ({ onChange }: Props) => {
    const [brands, setBrands] = useState([]);
    const [filteredBrands, setFilteredBrands] = useState([]);

    const usersForSelect = useMemo(() => {
        if (!filteredBrands) return [];
        return filteredBrands.map((brand: any) => ({ value: brand._id, label: brand.name }));
    }, [filteredBrands]);

    useEffect(() => {
        BrandsService.getAll({ limit: 1000 })
            .then(res => {
                if (res && res.data) {
                    setBrands(res.data);
                    setFilteredBrands(res.data);
                }
            });
    }, []);

    const onSearch = (value: string) => {
        setFilteredBrands(brands.filter((user: any) => user.name.toLowerCase().includes(value.toLowerCase())));
    };

    return (
        <Select
            showSearch
            placeholder="Выберите бренд"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={usersForSelect}
        />
    );
};

export default BrandSelect;