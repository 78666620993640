import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {ProductsService} from "../../services/products.service";
import {Button, Form, FormProps, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import {ProductFieldType} from "./props";
import {FieldData} from "../../shared/types/field-data.type";

export const ProductEdit = () => {
    const { id } = useParams();
    const [fields, setFields] = useState<FieldData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;

        setLoading(true);
        ProductsService.getById('' + id)
            .then(res => {
                if (res && res._id) {
                    setFields([
                        { name: ['name'], value: res.name || '' },
                        { name: ['description'], value: res.description || '' },
                        { name: ['priceDefault'], value: res.priceGroup?.default ?? 0 },
                        { name: ['priceExpert'], value: res.priceGroup?.expert ?? 0 },
                        { name: ['priceLeader'], value: res.priceGroup?.leader ?? 0 },
                        { name: ['priceTop'], value: res.priceGroup?.top ?? 0 },
                        { name: ['countInBlock'], value: res.countInBlock || '' },
                        { name: ['weight'], value: res.weight || '' },
                        { name: ['imageUrl'], value: res.imageUrl || '' },
                        { name: ['brandId'], value: res.brandId?._id || '' },
                    ]);
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }, [id]);

    const onFinish: FormProps<ProductFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            description: values.description,
            priceGroup: {
                default: Number(values.priceDefault),
                expert: Number(values.priceExpert),
                leader: Number(values.priceLeader),
                top: Number(values.priceTop),
            },
            countInBlock: Number(values.countInBlock),
            weight: Number(values.weight),
            imageUrl: values.imageUrl,
            brandId: values.brandId,
        };

        setLoadingSubmit(true);
        ProductsService.update(''+id, toSave)
            .then(res => {
                console.log(res);
                alert('Данные успешно сохранены!');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при сохранении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    if (loading) {
        return (
            <div>Загрузка...</div>
        );
    }

    return (
        <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            fields={fields}
        >
            <Form.Item<ProductFieldType>
                label="Название товара"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Описание товара"
                name="description"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <TextArea />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (партнер)"
                name="priceDefault"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (эксперт)"
                name="priceExpert"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (лидер)"
                name="priceLeader"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Цена (топ-партнер)"
                name="priceTop"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Кол-во упаковок в блоке"
                name="countInBlock"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Вес (кг)"
                name="weight"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="number" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="Ссылка на изображение"
                name="imageUrl"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<ProductFieldType>
                label="ID бренда"
                name="brandId"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Сохранить'}
                </Button>
            </Form.Item>
        </Form>
    );
};