import React, {useEffect, useState} from 'react';
import {Button, Form, FormProps, Input} from "antd";
import {LoginFieldType} from "./props";
import {AuthService} from "../../services/auth.service";
import styled from "styled-components";

export const Login = () => {
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            window.location.href = '/orders';
            return;
        }
    }, []);

    const onFinish: FormProps<LoginFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        setLoadingSubmit(true);
        AuthService.login('' + values.email, '' + values.password)
            .then((res) => {
                if (res?.access_token) {
                    localStorage.setItem('access_token', res.access_token);
                    window.location.href = '/orders';
                } else {
                    alert('Неверный логин и/или пароль');
                }
            })
            .catch(err => {
                console.error(err);
                alert('Неверный логин и/или пароль');
            })
            .finally(() => setLoadingSubmit(false));
    };

    return (
        <StyledContainer>
            <h3>Войти в систему</h3>
            <Form
                name="basic"
                layout="vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 400 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<LoginFieldType>
                    label="E-mail"
                    name="email"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                >
                    <Input type="text" />
                </Form.Item>

                <Form.Item<LoginFieldType>
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                >
                    <Input type="password" />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                        {loadingSubmit ? 'Подождите...' : 'Войти'}
                    </Button>
                </Form.Item>
            </Form>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`;