import BaseService from "./BaseService";

class Service extends BaseService<any> {
    constructor() {
        super({ resourcePath: '/auth' });
    }

    async login(email: string, password: string) {
        try {
            const response = await this.api.post(`${this.resourcePath}/login`, { email, password });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }
}

export const AuthService = new Service();