import moment from "moment/moment";
import 'moment/locale/ru';

// Устанавливаем локаль на русский
moment.locale('ru');

export const showObviousDate = (text: string) => {
    const date = moment(text);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const startOfWeek = moment().startOf('week');

    if (date.isSame(today, 'day')) {
        // Если дата сегодня
        return `Сегодня, ${date.format('HH:mm')}`;
    } else if (date.isSame(yesterday, 'day')) {
        // Если дата вчера
        return `Вчера, ${date.format('HH:mm')}`;
    } else if (date.isAfter(startOfWeek)) {
        // Если дата на этой неделе
        return `${date.format('dddd')}, ${date.format('HH:mm')}`;
    } else {
        // Если дата в другой неделе
        return date.format('DD.MM.YYYY, HH:mm');
    }
};