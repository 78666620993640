import React, {useEffect, useState} from 'react';
import {Button, Form, FormProps, Input} from "antd";
import {useParams} from "react-router-dom";
import {WarehousesService} from "../../services/warehouses.service";
import {WarehouseFieldType} from "./props";
import {FieldData} from "../../shared/types/field-data.type";

export const WarehouseEdit = () => {
    const { id } = useParams();
    const [fields, setFields] = useState<FieldData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;

        setLoading(true);
        WarehousesService.getById('' + id)
            .then(res => {
                if (res && res._id) {
                    setFields([
                        { name: ['name'], value: res.name || '' },
                        { name: ['street'], value: res.street || '' },
                        { name: ['apartment'], value: res.apartment || '' },
                        { name: ['office'], value: res.office || '' },
                        { name: ['floor'], value: res.floor || '' },
                    ]);
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }, [id]);

    const onFinish: FormProps<WarehouseFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            street: values.street,
            apartment: values.apartment,
            office: values.office,
            floor: values.floor,
            isMain: true,
        };

        setLoadingSubmit(true);
        WarehousesService.update(''+id, toSave)
            .then(res => {
                console.log(res);
                alert('Данные успешно сохранены!');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при сохранении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    if (loading) {
        return (
            <div>Загрузка...</div>
        );
    }

    return (
        <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            fields={fields}
        >
            <Form.Item<WarehouseFieldType>
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Улица"
                name="street"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Квартира"
                name="apartment"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Офис"
                name="office"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Этаж"
                name="floor"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Добавить'}
                </Button>
            </Form.Item>
        </Form>
    );
};