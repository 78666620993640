import React, {useCallback, useMemo} from 'react';
import {useFetchTableData} from "../../hooks/useFetchTableData";
import {SearchInput} from "../../components/SearchInput";
import {DataTable} from "../../components/DataTable";
import {VideoService} from "../../services/video.service";
import {Button, Flex, Space} from "antd";
import {useNavigate} from "react-router-dom";

export const Videos = () => {
    const {
        data,
        total,
        loading,
        page,
        limit,
        query,
        handleSearchChange,
        handleTableChange,
        loadData,
    } = useFetchTableData(VideoService.getAll.bind(VideoService));

    const navigate = useNavigate();

    const handleEdit = useCallback((id: string) => {
        return () => {
            if (!id) return;
            navigate(`/videos/edit/${id}`);
        };
    }, [navigate]);

    const handleAddNew = useCallback(() => {
        navigate(`/videos/add`);
    }, [navigate]);

    const handleRemove = useCallback((id: string) => {
        return () => {
            const isAgree = window.confirm('Вы точно хотите удалить?');

            if (!isAgree) return;

            VideoService.delete(id)
                .then(() => {
                    alert('Запись успешно удален!');
                    loadData();
                })
                .catch(err => {
                    console.log(err);
                    alert('Произошла ошибка при удалении');
                });
        };
    }, [loadData]);

    const columns = useMemo(() =>[
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Youtube код',
            dataIndex: 'youtubeCode',
            key: 'youtubeCode',
            render: (data: string) => (
                <a href={`https://www.youtube.com/watch?v=${data}`} target="_blank" rel="noreferrer">{data}</a>
            )
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, item: any) => {
                return (
                    <Flex wrap gap="2px" style={{ width: 80 }}>
                        <Button type="primary" size="small" block onClick={handleEdit(item._id)}>
                            Изменить
                        </Button>
                        <Button type="primary" danger size="small" block onClick={handleRemove(item._id)}>
                            Удалить
                        </Button>
                    </Flex>
                );
            },
        },
    ], [handleEdit, handleRemove]);

    return (
        <div>
            <Space direction="horizontal" style={{ marginBottom: 20 }}>
                <SearchInput value={query} onChange={handleSearchChange} />
                <Button onClick={handleAddNew} style={{ marginLeft: 10 }}>Добавить новую</Button>
            </Space>
            <DataTable
                data={data}
                columns={columns}
                loading={loading}
                total={total}
                page={page}
                limit={limit}
                onTableChange={handleTableChange}
            />
        </div>
    );
};