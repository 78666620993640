import React from 'react';
import {Select} from "antd";
import {
    OrderPaymentStatus,
    OrderPaymentStatusLabels,
    OrderStatus,
    OrderStatusLabels
} from "../../shared/types/order.type";

interface Props {
    onChange: (val: any) => void;
}

const StatusPaymentSelect = ({ onChange }: Props) => {

    const options = [
        { value: OrderPaymentStatus.PENDING, label: OrderPaymentStatusLabels.PENDING },
        { value: OrderPaymentStatus.PAID, label: OrderPaymentStatusLabels.PAID },
        { value: OrderPaymentStatus.ERROR, label: OrderPaymentStatusLabels.ERROR },
        { value: OrderPaymentStatus.CANCELLED, label: OrderPaymentStatusLabels.CANCELLED },
    ];

    return (
        <Select
            placeholder="Статус оплаты"
            optionFilterProp="label"
            onChange={onChange}
            options={options}
        />
    );
};

export default StatusPaymentSelect;