export enum OrderStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    DELIVERED = 'DELIVERED',
    CANCELLED = 'CANCELLED',
}

export enum OrderStatusLabels {
    PENDING = 'В ожидании',
    ACCEPTED = 'Заказ принят',
    DELIVERED = 'Товар выдан',
    CANCELLED = 'Отменен',
}

export enum OrderPaymentStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR',
}

export enum OrderPaymentStatusLabels {
    PENDING = 'В ожидании',
    PAID = 'Оплачен',
    CANCELLED = 'Отменен',
    ERROR = 'Ошибка',
}