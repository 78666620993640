import React, {useEffect, useState} from 'react';
import {Button, Form, FormProps, Input} from "antd";
import {useParams} from "react-router-dom";
import {VideoFieldType} from "./props";
import {FieldData} from "../../shared/types/field-data.type";
import {VideoService} from "../../services/video.service";

export const VideoEdit = () => {
    const { id } = useParams();
    const [fields, setFields] = useState<FieldData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;

        setLoading(true);
        VideoService.getById('' + id)
            .then(res => {
                if (res && res._id) {
                    setFields([
                        { name: ['name'], value: res.name || '' },
                        { name: ['youtubeCode'], value: res.youtubeCode || '' },
                    ]);
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }, [id]);

    const onFinish: FormProps<VideoFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            youtubeCode: values.youtubeCode,
        };

        setLoadingSubmit(true);
        VideoService.update(''+id, toSave)
            .then(res => {
                console.log(res);
                alert('Данные успешно сохранены!');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при сохранении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    if (loading) {
        return (
            <div>Загрузка...</div>
        );
    }

    return (
        <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            fields={fields}
        >
            <Form.Item<VideoFieldType>
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<VideoFieldType>
                label="YouTube код"
                name="youtubeCode"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Сохранить'}
                </Button>
            </Form.Item>
        </Form>
    );
};