import React, {useState} from 'react';
import {Button, Form, FormProps, Input} from "antd";
import {useNavigate} from "react-router-dom";
import {WarehousesService} from "../../services/warehouses.service";
import {WarehouseFieldType} from "../WarehouseEdit/props";

export const WarehouseAdd = () => {
    const navigate = useNavigate();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const onFinish: FormProps<WarehouseFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            street: values.street,
            apartment: values.apartment,
            office: values.office,
            floor: values.floor,
            isMain: true,
        };

        setLoadingSubmit(true);
        WarehousesService.create(toSave)
            .then(() => {
                alert('Запись успешно добавлен!');
                navigate('/warehouses');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при добавлении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    return (
        <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<WarehouseFieldType>
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Улица"
                name="street"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Квартира"
                name="apartment"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Офис"
                name="office"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<WarehouseFieldType>
                label="Этаж"
                name="floor"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Добавить'}
                </Button>
            </Form.Item>
        </Form>
    );
};