import React, {useCallback, useMemo} from 'react';
import {showObviousDate} from "../../shared/utils";
import {useFetchTableData} from "../../hooks/useFetchTableData";
import {SearchInput} from "../../components/SearchInput";
import {DataTable} from "../../components/DataTable";
import {ProductsService} from "../../services/products.service";
import {Button, Flex, Space} from 'antd';
import {useNavigate} from "react-router-dom";

export const Products = () => {
    const {
        data,
        total,
        loading,
        page,
        limit,
        query,
        handleSearchChange,
        handleTableChange,
        loadData,
    } = useFetchTableData(ProductsService.getAll.bind(ProductsService));

    const navigate = useNavigate();

    const handleEdit = useCallback((id: string) => {
        return () => {
            if (!id) return;
            navigate(`/products/edit/${id}`);
        };
    }, [navigate]);

    const handleAddNew = useCallback(() => {
        navigate(`/products/add`);
    }, [navigate]);

    const handleRemove = useCallback((id: string) => {
        return () => {
            const isAgree = window.confirm('Вы точно хотите удалить?');

            if (!isAgree) return;

            ProductsService.delete(id)
                .then(() => {
                    alert('Запись успешно удален!');
                    loadData();
                })
                .catch(err => {
                    console.log(err);
                    alert('Произошла ошибка при удалении');
                });
        };
    }, [loadData]);

    const columns = useMemo(() =>[
        {
            title: 'Изображение',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (data: any) => (
                <>
                    {data ? (
                        <img src={data} width={80} height={80} alt="image"/>
                    ) : null}
                </>
            )
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Цена за упаковку',
            dataIndex: 'priceGroup',
            key: 'priceGroup',
            render: (data: any) => (
                <>
                    <div>{data.default} ₸</div>
                    {/*<div>Партнер: {data.default} тг</div>*/}
                    {/*<div>Эксперт: {data.expert} тг</div>*/}
                    {/*<div>Лидер: {data.leader} тг</div>*/}
                    {/*<div>Топ-партнер: {data.top} тг</div>*/}
                </>
            )
        },
        {
            title: 'Упаковок в блоке',
            dataIndex: 'countInBlock',
            key: 'countInBlock',
        },
        {
            title: 'Вес',
            dataIndex: 'weight',
            key: 'weight',
            render: (data: any) => `${data} кг`
        },
        {
            title: 'Бренд',
            dataIndex: 'brandId',
            key: 'brandId',
            render: (data: any) => data?.name ?? 'Не указано'
        },
        {
            title: 'Дата создания',
            dataIndex: 'created',
            key: 'created',
            render: showObviousDate,
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, item: any) => {
                return (
                    <Flex wrap gap="2px" style={{ width: 80 }}>
                        <Button type="primary" size="small" block onClick={handleEdit(item._id)}>
                            Изменить
                        </Button>
                        <Button type="primary" danger size="small" block onClick={handleRemove(item._id)}>
                            Удалить
                        </Button>
                    </Flex>
                );
            },
        },
    ], [handleEdit, handleRemove]);

    return (
        <div>
            <Space direction="horizontal" style={{ marginBottom: 20 }}>
                <SearchInput value={query} onChange={handleSearchChange} />
                <Button onClick={handleAddNew} style={{ marginLeft: 10 }}>Добавить новую</Button>
            </Space>
            <DataTable
                data={data}
                columns={columns}
                loading={loading}
                total={total}
                page={page}
                limit={limit}
                onTableChange={handleTableChange}
            />
        </div>
    );
};