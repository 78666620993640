import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {OrdersService} from "../../services/orders.service";
import styled from "styled-components";
import {showObviousDate} from "../../shared/utils";
import {getStatusLabelWithColor, getStatusPaymentLabelWithColor} from "../Orders/utils";
import {Button, Flex} from "antd";
import {OrderStatus} from "../../shared/types/order.type";

export const OrderDetail = () => {
    const { id } = useParams();
    const [order, setOrder] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const loadData = useCallback(() => {
        if (!id) return;
        setLoading(true);
        OrdersService.getById(id)
            .then(res => {
                if (res?._id) {
                    setOrder(res);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    }, [id]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleChangeStatus = useCallback((status: OrderStatus) => {
        return () => {
            if (!id || !status || loadingStatus || !order) return;

            if (status === order.status) {
                alert('Текущий статус равен выбранному')
                return;
            }

            if (status === OrderStatus.DELIVERED) {
                const confirmDelivered = window.confirm(
                    'После изменение статуса на "Товар выдан", будет обновлен статус и блоки начислиться пользователю. Уверены?'
                );
                if (!confirmDelivered) return;
            }

            if (status === OrderStatus.CANCELLED) {
                const confirmCancelled = window.confirm(
                    'После изменение статуса на "Отменен", невозможно будет менять статус заказа. Уверены?'
                );
                if (!confirmCancelled) return;
            }


            setLoadingStatus(true);
            OrdersService.changeStatusById('' + id, status)
                .then(res => {
                    loadData();
                })
                .catch(err => {
                    console.log(err);
                    alert('Произошла ошибка при изменении');
                })
                .finally(() => setLoadingStatus(false));
        };
    }, [id, loadData, loadingStatus, order]);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (!order) {
        return <div>Нет данных</div>;
    }

    return (
        <div>
            <h3 style={{margin: '0 0 10px'}}>Заказ #{order.orderNumber}</h3>

            <StyledTable>
                <tbody>
                <tr>
                    <th style={{width: 230}}>ФИО</th>
                    <td>{order.customerFullName}</td>
                </tr>
                <tr>
                    <th>E-mail</th>
                    <td>{order.customerEmail}</td>
                </tr>
                <tr>
                    <th>Склад клиента</th>
                    <td>{order.customerStorage}</td>
                </tr>
                <tr>
                    <th>Выбранный склад</th>
                    <td>{order.warehouse.name}</td>
                </tr>
                <tr>
                    <th>Дата заказа</th>
                    <td>{showObviousDate(order.orderDate)}</td>
                </tr>
                <tr>
                    <th>Статус оплаты</th>
                    <td>{getStatusPaymentLabelWithColor(order.statusPayment)}</td>
                </tr>
                <tr>
                    <th>Статус</th>
                    <td>
                        <div>{getStatusLabelWithColor(order.status)}</div>
                        {(order.status !== OrderStatus.DELIVERED && order.status !== OrderStatus.CANCELLED) && (
                            <div className="actions-wrapper">
                                <Flex gap="small" wrap>
                                    <Button
                                        size="small"
                                        onClick={handleChangeStatus(OrderStatus.PENDING)}
                                        disabled={order.status === OrderStatus.PENDING}
                                    >
                                        В ожидании
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={handleChangeStatus(OrderStatus.ACCEPTED)}
                                        disabled={order.status === OrderStatus.ACCEPTED}
                                    >
                                        Заказ принят
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={handleChangeStatus(OrderStatus.DELIVERED)}
                                        disabled={order.status === OrderStatus.DELIVERED}
                                    >
                                        Товар выдан
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={handleChangeStatus(OrderStatus.CANCELLED)}
                                        disabled={order.status === OrderStatus.CANCELLED}
                                    >
                                        Отменен
                                    </Button>
                                </Flex>
                            </div>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Бренд</th>
                    <td>{order.brand.name}</td>
                </tr>
                <tr>
                    <th>Кол-во блоков</th>
                    <td>{order.totalCount}</td>
                </tr>
                <tr>
                    <th>Сумма заказа</th>
                    <td>{order.totalAmount} тг</td>
                </tr>
                {order.referralEarnings && (
                    <tr>
                        <th>Заработок реферала</th>
                        <td>
                            {order.referralEarnings} тг {order.referralUserId && (<span>({order.referralUserId})</span>)}
                        </td>
                    </tr>
                )}
                </tbody>
            </StyledTable>

            <h3 style={{margin: '30px 0 10px'}}>Товары:</h3>
            <StyledTable>
                <thead>
                <tr>
                    <th>Товар</th>
                    <th>Цена</th>
                    <th>Упаковок в блоке</th>
                    <th>Кол-во блоков</th>
                    <th>Сумма</th>
                </tr>
                </thead>
                <tbody>
                {order.items.map((item: any, index: number) => (
                    <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.price} тг</td>
                        <td>{item.countInBlock}</td>
                        <td>{item.quantity}</td>
                        <td>{item.totalAmount} тг</td>
                    </tr>
                ))}
                </tbody>
            </StyledTable>
        </div>
    );
};

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    th, td {
        border: 1px solid #ddd;
        padding: 5px;
    }

    .actions-wrapper {
        margin-top: 10px;
    }
`;