import React from 'react';
import {Select} from "antd";
import {OrderStatus, OrderStatusLabels} from "../../shared/types/order.type";

interface Props {
    onChange: (val: any) => void;
}

const StatusSelect = ({ onChange }: Props) => {

    const options = [
        { value: OrderStatus.PENDING, label: OrderStatusLabels.PENDING },
        { value: OrderStatus.ACCEPTED, label: OrderStatusLabels.ACCEPTED },
        { value: OrderStatus.DELIVERED, label: OrderStatusLabels.DELIVERED },
        { value: OrderStatus.CANCELLED, label: OrderStatusLabels.CANCELLED },
    ];

    return (
        <Select
            placeholder="Статус заказа"
            optionFilterProp="label"
            onChange={onChange}
            options={options}
        />
    );
};

export default StatusSelect;