import React from 'react';
import { Input } from 'antd';

interface SearchInputProps {
    value: string;
    onChange: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ value, onChange }) => {
    return (
        <Input
            placeholder="Поиск"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};