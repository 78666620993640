import BaseService from "./BaseService";
import {OrderStatus} from "../shared/types/order.type";

class Service extends BaseService<any> {
    constructor() {
        super({ resourcePath: '/orders' });
    }

    async changeStatusById(id: string, status: OrderStatus) {
        try {
            const response = await this.api.post(`${this.resourcePath}/change-status/${id}`, { status });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async filter({ page = 1, limit = 10, ...otherProps }) {
        try {
            const response = await this.api.get(
                this.resourcePath,
                {
                    params: {
                        page,
                        limit,
                        ...otherProps,
                    },
                },
            );
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async exportExcel({ page = 1, limit = 10, ...otherProps }) {
        try {
            const response = await this.api.get(
                `${this.resourcePath}/export-excel`,
                {
                    responseType: 'blob',
                    params: {
                        page,
                        limit,
                        ...otherProps,
                    },
                },
            );
            return response;
        } catch (error) {
            this.handleError(error);
        }
    }
}

export const OrdersService = new Service();