import React, {useEffect, useMemo, useState} from 'react';
import {Select} from "antd";
import {UsersService} from "../../services/users.service";

interface Props {
    onChange: (val: any) => void;
}

const CustomerSelect = ({ onChange }: Props) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const usersForSelect = useMemo(() => {
        if (!filteredUsers) return [];
        return filteredUsers.map((user: any) => ({ value: user._id, label: user.fullName }));
    }, [filteredUsers]);

    useEffect(() => {
        UsersService.getAll({ limit: 1000 })
            .then(res => {
                if (res && res.data) {
                    setUsers(res.data);
                    setFilteredUsers(res.data);
                }
            });
    }, []);

    const onSearch = (value: string) => {
        setFilteredUsers(users.filter((user: any) => user.fullName.toLowerCase().includes(value.toLowerCase())));
    };

    return (
        <Select
            style={{ width: 250 }}
            showSearch
            placeholder="Выберите клиента"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={usersForSelect}
        />
    );
};

export default CustomerSelect;