import React, {useEffect, useState} from 'react';
import {Button, Form, FormProps, Input} from "antd";
import {useParams} from "react-router-dom";
import {BrandFieldType} from "./props";
import {BrandsService} from "../../services/brands.service";
import {FieldData} from "../../shared/types/field-data.type";

export const BrandEdit = () => {
    const { id } = useParams();
    const [fields, setFields] = useState<FieldData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;

        setLoading(true);
        BrandsService.getById('' + id)
            .then(res => {
                if (res && res._id) {
                    setFields([
                        { name: ['name'], value: res.name || '' },
                        { name: ['logo'], value: res.logo || '' },
                        { name: ['defaultStart'], value: res.defaultStart || '' },
                        { name: ['defaultEnd'], value: res.defaultEnd || '' },
                        { name: ['expertStart'], value: res.expertStart || '' },
                        { name: ['expertEnd'], value: res.expertEnd || '' },
                        { name: ['leaderStart'], value: res.leaderStart || '' },
                        { name: ['leaderEnd'], value: res.leaderEnd || '' },
                        { name: ['topStart'], value: res.topStart || '' },
                        { name: ['topEnd'], value: res.topEnd || '' },
                    ]);
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }, [id]);

    const onFinish: FormProps<BrandFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            name: values.name,
            logo: values.logo,
            defaultStart: Number(values.defaultStart),
            defaultEnd: Number(values.defaultEnd),
            expertStart: Number(values.expertStart),
            expertEnd: Number(values.expertEnd),
            leaderStart: Number(values.leaderStart),
            leaderEnd: Number(values.leaderEnd),
            topStart: Number(values.topStart),
            topEnd: Number(values.topEnd),
        };

        setLoadingSubmit(true);
        BrandsService.update(''+id, toSave)
            .then(res => {
                console.log(res);
                alert('Данные успешно сохранены!');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при сохранении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    if (loading) {
        return (
            <div>Загрузка...</div>
        );
    }

    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            fields={fields}
        >
            <Form.Item<BrandFieldType>
                label="Название бренда"
                name="name"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<BrandFieldType>
                label="Ссылка на логотип"
                name="logo"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item label="Партнер (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="defaultStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="defaultEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>


            <Form.Item label="Эксперт (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="expertStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="expertEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>


            <Form.Item label="Лидер (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="leaderStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="leaderEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>


            <Form.Item label="Топ-партнер (кол-во блоков)">
                <Form.Item<BrandFieldType>
                    name="topStart"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="От" />
                </Form.Item>
                <Form.Item<BrandFieldType>
                    name="topEnd"
                    rules={[{ required: true, message: 'Заполните поле' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)', margin: '0 8px' }}
                >
                    <Input type="number" placeholder="До" />
                </Form.Item>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Сохранить'}
                </Button>
            </Form.Item>
        </Form>
    );
};