import React, {useState} from 'react';
import {Button, Form, FormProps, Input} from "antd";
import {useNavigate} from "react-router-dom";
import {FaqFieldType} from "../FaqEdit/props";
import {FaqService} from "../../services/faq.service";
import TextArea from "antd/es/input/TextArea";

export const FaqAdd = () => {
    const navigate = useNavigate();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const onFinish: FormProps<FaqFieldType>['onFinish'] = (values) => {
        if (loadingSubmit) return;

        const toSave = {
            question: values.question,
            answer: values.answer,
        };

        setLoadingSubmit(true);
        FaqService.create(toSave)
            .then(() => {
                alert('Запись успешно добавлен!');
                navigate('/faq');
            })
            .catch(err => {
                console.log(err);
                alert('Произошла ошибка при добавлении');
            })
            .finally(() => setLoadingSubmit(false));
    };

    return (
        <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<FaqFieldType>
                label="Вопрос"
                name="question"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <Input type="text" />
            </Form.Item>

            <Form.Item<FaqFieldType>
                label="Ответ"
                name="answer"
                rules={[{ required: true, message: 'Заполните поле' }]}
            >
                <TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={loadingSubmit}>
                    {loadingSubmit ? 'Подождите...' : 'Добавить'}
                </Button>
            </Form.Item>
        </Form>
    );
};