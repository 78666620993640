import React from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import {useNavigate} from "react-router-dom";

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {
        key: 'main-menu',
        label: 'Основное меню',
        type: 'group',
        children: [
            { key: 'orders', label: 'Заказы' },
            { key: 'products', label: 'Товары' },
            { key: 'brands', label: 'Бренды' },
            { key: 'warehouses', label: 'Склады Otan' },
            { key: 'faq', label: 'FAQ' },
            { key: 'videos', label: 'Видео уроки' },
            { key: 'users', label: 'Пользователи' },
            { key: 'logout', label: 'Выход' },
        ],
    },
];

export const LeftColumn = () => {
    const navigate = useNavigate();

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'logout') {
            localStorage.removeItem('access_token');
            window.location.href = '/login';
            return;
        }
        navigate(`/${e.key}`);
    };

    return (
        <Menu
            onClick={onClick}
            style={{ width: '100%' }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={items}
        />
    );
};